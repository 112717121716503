import { FC, useEffect, useRef, useState } from "react";
import { SearchDropDownComponent } from "../../../components";
import { CityIcon } from "../../../styles/icons";
import { API_CONSTANTS, NAV_TAGS } from "../../../types/constants";
import {
  EntityFinancialFilter,
  ReportDoc,
} from "../../../types/interfaces/entityInterfaces";
import { getCitiesFinancial } from "../cityLandingPage.service";
import EntityListCardYear from "../../../components/entityListCard/EntityListCardYear";
import { CITY_REPORTS, CITY_SPECIFIC_REPORTS } from "../../../types/constants/powerBiConstants";
import { models } from "powerbi-client";
import { requestCityByEntityID } from "../../../api/searchType.api";
import ReportSectionCityPage from "../../../components/reportSection/ReportSectionCityPage";
import { CheveronLeft, CheveronRight } from "styled-icons/zondicons";
import { useLocation, useNavigate } from "react-router-dom";

type CityNarrative = {
  City_Narrative: string;
};

const CitySearchFeature: FC = () => {
  const [searchListData, setSearchListData] = useState<EntityFinancialFilter[]>(
    []
  );
  const selectedYear = "2022";
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNarrative, setIsLoadingNarrative] = useState(true);
  const [selectedItem, setSelectedItem] = useState<EntityFinancialFilter>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [cityNarrative, setCityNarrative] = useState<string>("");
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const salaryTabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getCitiesCall = async () => {
      setSearchListData([]);
      setIsLoading(true);
      try {
        const res: EntityFinancialFilter[] = await getCitiesFinancial(
          selectedYear
        );

        setSearchListData(() => res);
        handleSelectedItem(res);
        getCityDetail();
      } finally {
        setIsLoading(false);
      }
    };
    getCitiesCall();
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.SALARY_TAB) {
        setSelectedTab(3);
        salaryTabRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  const handleSelectedItem = (cities: EntityFinancialFilter[]) => {
    if (!selectedItem || !cities.some((city) => city.id === selectedItem.id)) {
      setSelectedItem(cities[0]);
    } else {
      setSelectedItem(cities.find((city) => city.id === selectedItem.id));
    }
  };

  const onTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const getCityDetail = async (cityId?: string) => {
    try {
      setIsLoadingNarrative(true);
      const res: CityNarrative[] = await requestCityByEntityID(cityId ?? "628");

      setCityNarrative(res[0].City_Narrative);
      setIsLoadingNarrative(false);
    } catch (error) {
      setIsLoadingNarrative(false);
    }
  };

  const onCitySelect = (item: EntityFinancialFilter) => {
    setSelectedItem(item);
    getCityDetail(item.id?.toString());
  };

  const onBackTap = () => {
    if (selectedTab === 0) {
      setSelectedTab(5);
      return;
    }

    setSelectedTab(selectedTab - 1);
  };

  const onForwardTap = () => {
    if (selectedTab === 5) {
      setSelectedTab(0);
      return;
    }

    setSelectedTab(selectedTab + 1);
  };

  return (
    <>
      <div className="search-entity-section">
        <div className="search-entity-text">
          <h4 className="city-entity-search-header">SEARCH CITIES</h4>
          <p className="city-entity-search-subheader body-large">
            {
              "Use the search functionality to enter a city name and view detailed information, including the city's budget, revenue, expenditure, balances, salaries, and historical data."
            }
          </p>
        </div>
        <div className="search-input-container">
          <h6 className="city-entity-search-menu-header">Search City</h6>
          {isLoading ? (
            <h6>Loading...</h6>
          ) : (
            <div>
              <SearchDropDownComponent
                CustomIcon={CityIcon}
                isEntitiesFiltered={true}
                entitiesData={searchListData}
                selectedItem={selectedItem}
                setSelectedItem={onCitySelect}
              />
            </div>
          )}
        </div>
      </div>
      <div className="city-data">
        <div className="city">
          <div className="city__details">
            <h2 className="city__heading">
              City of {selectedItem?.name ?? "Aberdeen"}
            </h2>
            {isLoadingNarrative ? (
              <p className="city__details">Loading...</p>
            ) : (
              <p className="city__details">{cityNarrative}</p>
            )}
          </div>

          <div className="city__picture">
            <img
              src={`${API_CONSTANTS.imageBucket}/city-images/${
                selectedItem?.name.replace(/ /g, "") ?? "Aberdeen"
              }Image${selectedItem?.id ?? 628}.png`}
              alt="City-picture"
            />
          </div>
        </div>
      </div>
      <div className="cityInformation">
        <div className="cityInformation__tabsContainer">
          <div
            onClick={() => {
              onTabChange(0);
            }}
            className={`cityInformation__tab ${
              selectedTab == 0 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Budget</h6>
          </div>
          <div
            onClick={() => {
              onTabChange(1);
            }}
            className={`cityInformation__tab ${
              selectedTab == 1 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Revenues</h6>
          </div>
          <div
            onClick={() => {
              onTabChange(2);
            }}
            className={`cityInformation__tab ${
              selectedTab == 2 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Expenditures</h6>
          </div>

          <div
            onClick={() => {
              onTabChange(3);
            }}
            ref={salaryTabRef}
            className={`cityInformation__tab ${
              selectedTab == 3 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Salaries</h6>
          </div>
          <div
            onClick={() => {
              onTabChange(4);
            }}
            className={`cityInformation__tab ${
              selectedTab == 4 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Fund Balances</h6>
          </div>
          <div
            onClick={() => {
              onTabChange(5);
            }}
            className={`cityInformation__tab ${
              selectedTab == 5 ? "cityInformation__tab--selected" : ""
            }`}
          >
            <h6>Documents</h6>
          </div>
        </div>

        {selectedTab == 0 && (
          <ReportSectionCityPage
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="cityReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            title="Budget"
            scrollable
            description="Before passing the yearly appropriation ordinance, each city council must craft a budget. The approved budget is the projected financial plan for the upcoming operating year and authorizes the city to collect revenues and make expenditures while promoting accountability, as well as providing administrative and managerial controls.
In the following report, you will see the selected city's total budget broken out by revenues and expenditures."
            reportId={CITY_SPECIFIC_REPORTS.CityBudgetDetails.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-budget.png"
            visualName={CITY_SPECIFIC_REPORTS.CityBudgetDetails.visualName}
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "City Budget",
                    column: "City",
                  },
                  operator: "In",
                  values: [selectedItem?.name ?? "Aberdeen"],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {selectedTab == 1 && (
          <ReportSectionCityPage
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="cityReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            title="Revenue by Category"
            scrollable
            description="City revenues are the income a city earns from taxes, fees, and grants. They fund essential services like roads, parks, and public safety, ensuring the city runs smoothly and serves its residents effectively.
In this report, you will find the various revenue sources of the city broken down by category. Explore the interactive data to learn more about the city's revenues by category."
            reportId={CITY_SPECIFIC_REPORTS.TopRevenueDetails.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-revenue-by-category.png"
            visualName={CITY_SPECIFIC_REPORTS.TopRevenueDetails.visualName}
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "City Budget",
                    column: "City",
                  },
                  operator: "In",
                  values: [selectedItem?.name ?? "Aberdeen"],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {selectedTab == 2 && (
          <ReportSectionCityPage
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="cityReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title="Expenditure by Category"
            description="City expenditures are the money a city spends on services and projects like maintaining roads, parks, and public safety. It covers expenses such as salaries for city employees, equipment, and infrastructure improvements. By carefully allocating these funds, the city aims to enhance the quality of life for its residents and ensure the smooth operation of essential services.
In this report, you will find the various categories on which the city expends funds. Explore the interactive data to learn more about the city's expenditures by category."
            reportId={CITY_SPECIFIC_REPORTS.TopExpenditureDetails.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-expenditure-by-category.png"
            visualName={CITY_SPECIFIC_REPORTS.TopExpenditureDetails.visualName}
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "City Budget",
                    column: "City",
                  },
                  operator: "In",
                  values: [selectedItem?.name ?? "Aberdeen"],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {selectedTab == 3 && (
          <ReportSectionCityPage
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="cityReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title="City Salaries"
            description="In this section,&nbsp;you can explore positions and salaries for city employees in the state of Idaho.
This report includes position and salary information for the city you have selected. Explore the interactive salary data to learn more about what each city expends to pay its employees. The information provided includes the salary amounts based on the most recent report, which is updated every quarter."
            reportId={CITY_SPECIFIC_REPORTS.CitySalariesDetails.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-salaries.png"
            visualName={CITY_SPECIFIC_REPORTS.CitySalariesDetails.visualName}
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "City_Employee_Salary",
                    column: "City",
                  },
                  operator: "In",
                  values: [selectedItem?.name ?? "Aberdeen"],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {selectedTab == 4 && (
          <ReportSectionCityPage
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="cityReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title={CITY_REPORTS.FundBalanceClassification.title}            
            description={
              CITY_REPORTS.FundBalanceClassification.description
            }
            reportId={CITY_SPECIFIC_REPORTS.CityFundBalancesDetails.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-fund-balances-by-classification.png"
            visualName={
              CITY_SPECIFIC_REPORTS.CityFundBalancesDetails.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "rep City_Fund_Balances",
                    column: "City",
                  },
                  operator: "In",
                  values: [selectedItem?.name ?? "Aberdeen"],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}

        {selectedTab == 5 && (
          <div className="cityInformation__detailContainer">
            <div className="reportMobileNav">
              <div
                onClick={onBackTap}
                className="reportMobileNav__iconContainer"
              >
                <CheveronLeft />
              </div>
              <h5 className="reportMobileNav__text">Documents</h5>
              <div
                onClick={onForwardTap}
                className="reportMobileNav__iconContainer"
              >
                <CheveronRight />
              </div>
            </div>
            <div className="cityInformation__data">
              <h2 className="cityInformation__heading">Documents</h2>
              <p className="cityInformation__details">
                Below you will find defined fiscal year, the actuals for the
                previous fiscal years, and an audit if applicable.
              </p>
              <div className="filter-table-city auto-height-table">
                <EntityListCardYear year="2021" cityId={selectedItem?.id} />
                <EntityListCardYear year="2022" cityId={selectedItem?.id} />
                <EntityListCardYear year="2023" cityId={selectedItem?.id} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CitySearchFeature;
