import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { City } from "styled-icons/boxicons-solid";
import IdahoStateSeal from "../../styles/images/idahoStateSeal.webp";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  InfoBubble,
  LoadingBar,
  LoadingState,
  UAMSection,
  ReportSection,
} from "../../components";
import { PopulationIcon } from "../../styles/icons";
import {
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
  NAV_TAGS,
} from "../../types/constants";
import { requestFiscalYearList } from "../../api/searchType.api";
import CitySearchFeature from "./cityComponents/CitySearchFeature";
import "./cityLandingPage.scss";
import { CITY_REPORTS } from "../../types/constants/powerBiConstants";

const CityLandingPage = () => {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.InProgress
  );
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const exploreDataRef = useRef<HTMLDivElement>(null);
  const allCityBudgetsRef = useRef<HTMLDivElement>(null);
  const citySalaryCostsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const fiscalYears = await requestFiscalYearList();
      setFiscalYears(fiscalYears);
    };

    handleLoad();
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.EXPLORE_CITY_DATA) {
        exploreDataRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.CITY_ALL_CITY_BUDGETS) {
        allCityBudgetsRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.CITY_SALARY_COSTS) {
        citySalaryCostsRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  return (
    <div className="landing-page-container landing-page-picture-background">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">
                  Explore Idaho's Cities
                </h2>
                <h6 className="landing-page-hero-subheader">
                  Idaho has 198 cities ranging in size and population while
                  providing a unique set of services to its citizens
                </h6>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderTotalCities.svg"
                }
                Icon={City}
                infoAmount={"198"}
                infoDetailText={"Cities"}
                bottomHeader={"Incorporated Cities"}
                bottomSubHeader={"As of Census 2023"}
              />
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderLargestCity.svg"
                }
                infoDetailText={"People"}
                Icon={PopulationIcon}
                infoAmount={"235,421"}
                bottomHeader={"City of Boise"}
                bottomSubHeader={"As of Census 2023"}
              />
              <InfoBubble
                titleImage={
                  "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com/dashboard-header-images/CurvedHeaderSmallestCity.svg"
                }
                Icon={PopulationIcon}
                infoAmount={"<5"}
                infoDetailText={"People"}
                bottomHeader={"City of Warm River"}
                bottomSubHeader={"As of Census 2023"}
              />
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-6.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.CITY.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.CITY.TITLE}
          description={ABOUT_SECTION_CONSTANTS.CITY.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.CITY.DATA_INFO}
          ref={exploreDataRef}
        />
        <section className="city-report-section">
          <div className="app-padding">
            <h4 className="explore-city-heading">
              EXPLORE HIGH LEVEL CITY DATA
            </h4>
            <p className="explore-city-content">
              Below you will find interactive reports for budgets, fund
              balances, salaries <br></br>and more. See information next to
              reports for helpful descriptions and insights.
            </p>
          </div>
          <ReportSection
            scrollable
            title="Total Budget for All Cities"
            description={
              "Under Idaho Code § 50-1002, city councils are responsible for preparing the city budget, generally done in collaboration with the mayor and a budget officer, such as the city clerk, finance officer, city administrator, or city manager. The approved budget serves as a financial plan for the upcoming year, authorizing the city to collect revenues and allocate funds while ensuring accountability and maintaining administrative and financial control. By law, city budgets must be balanced, meaning the city’s expenses cannot exceed its revenues. \n\nTo learn more about how your city manages its finances, dive into our interactive budget data tool. This resource allows you to review detailed budgets for each city, broken down into uniform categories, and compare by fiscal year."
            }
            reportId={CITY_REPORTS.TotalBudgetForCities.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-total-budget-for-cities.png"
          />
          <ReportSection
            scrollable
            title="Top City Revenues by Category"
            description={
              "Cities rely on a variety of revenue sources to fund services and facilities provided to their citizens. These revenue sources include but are not limited to, user fees, licensing/permitting, taxes, and federal funding. To learn more about where each of the Idaho cities collects their money from, dive into the interactive data to see the most common revenue sources. The information provided includes the largest revenue sources for cities broken down by fiscal year."
            }
            reportId={CITY_REPORTS.TotalRevenuesByCategory.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-total-city-revenues-by-category.png"
          />
          <ReportSection
            scrollable
            title="Top City Expenditures by Category"
            description={
              "Cities in Idaho provide a wide range of essential services to residents and businesses, managed by elected leaders and city employees. These services include public works like water systems and waste management, maintaining streets and roads, planning and zoning for community growth, and offering various community programs focused on safety and sustainability. Beyond these, cities manage a variety of additional services tailored to the unique needs of their residents and local businesses. \n\nTo learn how cities allocate funds for these services, explore our interactive budget data tool. It offers detailed breakdowns of city expenditures by category and tracks spending across Idaho cities broken down by fiscal year."
            }
            reportId={CITY_REPORTS.TotalExpendituresByCategory.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-total-city-expenditures-by-category.png"
          />
          <ReportSection
            scrollable
            title="City Salaries"
            description={
              "This report includes position and salary information for city employees in the state of Idaho. Explore the interactive salary data to learn more about what each city expends to pay its employees. The information provided includes the salary amounts based on the most recent report. The data was last updated as of June 30th, 2024."
            }
            reportId={CITY_REPORTS.CitySalaries.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-salaries.png"
          />
          <ReportSection
            scrollable
            title={CITY_REPORTS.FundBalanceClassification.title}
            description={
              CITY_REPORTS.FundBalanceClassification.description
            }
            reportId={CITY_REPORTS.FundBalanceClassification.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-fund-balance-classification.png"
          />
        </section>
        <span className="hero-separator" />
        <div ref={allCityBudgetsRef}>
          <CitySearchFeature />
        </div>
        <div>
          <UAMSection />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default CityLandingPage;
