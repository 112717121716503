export const URL_CONSTANTS = {
  HOME: "/",
  WORKFORCE_SALARY: "/Workforce&Salary",
  STATE: "/State",
  STATE_AGENCIES: "/StateAgencies",
  EDUCATION: "/Education",
  COUNTY: "/Counties",
  CITY: "/City",
  LOCAL_DISTRICT: "/LocalDistrict",
  VENDOR_PAYMENTS: "/VendorPayments",
  PUBLIC_RECORD_REQUEST: "/PublicRecordRequest",
  ERROR: "/Error",
};

export const EXTERNAL_SITE_LINKS = {
  MOU: "https://transparencyresources.idaho.gov/transparentidaho/Pages/StatewideAgreementsContractsDisplay.aspx",
  ANNUAL_COMPREHENSIVE_FINANCIAL_REPORT:
    "https://www.sco.idaho.gov/LivePages/annual-comprehensive-financial-reports.aspx",
  EMPLOYMENT_VERIFICATION: "https://www.experianverify.com",
  UNCASHED_WARRANTS:
    "https://stateofidaho.qualtrics.com/jfe/form/SV_cxdN5JSvNhX7nAG",
  SCHEDULE_OF_EXP_OF_FIN_REPORTS:
    "https://www.sco.idaho.gov/LivePages/scheduleofexpendituresoffedawards.aspx",
  TOWN_HALL_IDAHO: "https://townhall.idaho.gov/",
  ADDITIONAL_RECOURSES:
    "https://www.sco.idaho.gov/LivePages/TIAdditionalResources.aspx",
};
