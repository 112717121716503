import React, { FC, useEffect, useRef, useState, useCallback, Suspense } from "react";
import PowerBi from "../../powerBi/PowerBi";
import { models } from "powerbi-client";
import Loader from "../../../styles/loader.svg";
import "./lazyLoadPowerBi.scss"

interface LazyLoadPowerBiProps {
  reportId: string;
  slicerFilterState?: models.ISlicerState;
  visualName?: string;
  fullScreen?: boolean;
  rootMargin?: string;
  //NOTE: threshold must be 0 - 1 value
  threshold?: number;
  placeholderImg?: string;
}

const LazyLoadPowerBi: FC<LazyLoadPowerBiProps> = ({
  reportId,
  slicerFilterState,
  visualName,
  fullScreen = false,
  rootMargin = "0px",
  threshold = 0,
  placeholderImg,
}) => {
  const [shouldLoadIframe, setShouldLoadIframe] = useState(true);
  const [reportLoaded, setReportLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

 
  // const debounce = (func: (...args: any[]) => void, delay: number) => {
  //   let timeout: NodeJS.Timeout;
  //   return (...args: any[]) => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  // const handleIntersection = useCallback(
  //   debounce((entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
  //     if (entry.isIntersecting) {
  //       setShouldLoadIframe(true);
  //       observer.unobserve(entry.target);
  //     }
  //   }, 1000), 
  //   []
  // );

  // useEffect(() => {
  //   if (!("IntersectionObserver" in window)) {
  //     setShouldLoadIframe(true);
  //     return;
  //   }

  //   let observer: IntersectionObserver;
  //   const currentRef = containerRef.current;

  //   if (currentRef) {
  //     observer = new IntersectionObserver(
  //       (entries, obs) => {
  //         entries.forEach((entry) => handleIntersection(entry, obs));
  //       },
  //       {
  //         rootMargin,
  //         threshold,
  //       }
  //     );
  //     observer.observe(currentRef);
  //   }

  //   return () => {
  //     if (observer && currentRef) {
  //       observer.unobserve(currentRef);
  //     }
  //   };
  // }, [handleIntersection, rootMargin, threshold]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      {!reportLoaded && (
        <div  className={`power-bi-container ${fullScreen ? "full-screen-report" : ""}`}>
          {placeholderImg && <div className={`placeholder power-bi-container ${fullScreen ? "full-screen-report" : ""}`} style={{background: `url(${placeholderImg})`}} />}
          {shouldLoadIframe && <img className="loader" src={Loader} alt="loading..." />}
        </div>
      )}
      {shouldLoadIframe && (
        <span className={reportLoaded ? "" : "hide-report"}>
          <PowerBi
            reportId={reportId}
            slicerFilterState={slicerFilterState}
            visualName={visualName}
            setReportLoaded={() => setReportLoaded(true)}
            reportLoaded={reportLoaded}
            fullScreen={fullScreen}
            />
        </span>
      )}
    </div>
  );
};

export default LazyLoadPowerBi;
